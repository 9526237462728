import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "techniques",
    path: "accueil / nos métiers / techniques / Intégrateur-Packageur"
  },
  title: "Intégrateur / Packageur", 
	missions:[
		{
		  text: "Optimiser et améliorer les standards et les processus (système ou applicatif)" 
		},

	],
	key_skills: [
		{
		  text: "Connaissances techniques des différents outils et langages de développement orientés outils" 
		},
		{
		  text: "Communication écrite et orale" 
		},
		{
		  text: "Compréhension de l’anglais technique" 
		},
	],
	prerequisites:[
		{
		  text: "Posséder un niveau de connaissances minimum validé par un questionnaire technique" 
		},
	],
	activities:[
		{
		  text: "Intégrer des applications" 
		},
		{
		  text: "Réaliser les paramétrages spécifiques" 
		},
		{
		  text: "Effectuer et valider les tests fonctionnels et techniques" 
		},
		{
		  text: "Rédiger les spécifications techniques" 
		},
		{
		  text: "Assurer la résolution des anomalies" 
		},
		{
		  text: "Réaliser les actions de distribution et s’assurer de leur bon déroulement" 
		},
		{
		  text: "Mettre à jour le référentiel (catalogue)" 
		},
		{
		  text: "Identifier et proposer des évolutions contribuant à l’amélioration de la qualité de service" 
    	}
  	]
}
const LocalPage = () => (
  <PagePoste {...pageData}/>
);

export default LocalPage;